/** @jsx jsx */
import { jsx } from "theme-ui"
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useContext } from "react"
import { graphql } from "gatsby"

import HeaderContext from "../context/header-context"
import MotionWrapper from "../components/motion-wrapper"
import Container from "../components/container"
import Slab from "../components/slab"
import Heading from "../components/heading"
import Copy from "../components/copy"
import ContentBuilder from "../components/content-builder"
import SEO from "../components/seo"

const SpecialismSinglePage = ({ data }) => {
  const {
    entry: [entry],
  } = data.craft

  const { updateHeaderState } = useContext(HeaderContext)

  useEffect(() => {
    updateHeaderState({
      section: "Specialisms",
      sectionLink: "/does",
      inverseHeaderColor: true,
    })
  }, [updateHeaderState])

  const footerLinks = [
    {
      title: "View our work",
      slug: "/made",
    },
    {
      title: "Contact us",
      slug: "/contact",
    },
    {
      title: "Go back home",
      slug: "/",
    },
  ]

  return (
    <>
      <SEO
        description={entry.metaDescription}
        title={entry.metaTitle ? entry.metaTitle : entry.title}
        image={entry.metaImage[0].url}
      />
      <MotionWrapper footerLinks={footerLinks}>
        <Slab first color="text">
          <Container>
            <Heading hLevel={1} fullOpacity>
              {entry.title}
            </Heading>
            <Copy markup={entry.introText} />
          </Container>
        </Slab>
        <ContentBuilder blocks={entry.contentBuilder} />
      </MotionWrapper>
    </>
  )
}

export const SpecialismSinglePageQuery = graphql`
  query($entryId: Int) {
    craft {
      entry: entries(type: ["specialisms"], id: [$entryId]) {
        ... on craft_specialisms_specialisms_Entry {
          title
          introText
          contentBuilder {
            ...ContentBuilder
          }
          metaTitle
          metaDescription
          metaImage {
            url
          }
        }
      }
    }
  }
`

export default SpecialismSinglePage
